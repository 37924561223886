import React, { useState } from 'react';
import {Link} from "react-router-dom";

const PlumberSectionPart: React.FC = () => {
  const [showEmergencyContent, setShowEmergencyContent] = useState(false);
  const [showInstallationContent, setShowInstallationContent] = useState(true);
  const [activeLink, setActiveLink] = useState('installation');

  const handleClickEmergency = () => {
    setShowEmergencyContent(true);
    setShowInstallationContent(false);
    setActiveLink('urgence');
  };

  const handleClickInstallation = () => {
    setShowEmergencyContent(false);
    setShowInstallationContent(true);
    setActiveLink('installation');
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 lg:py-14 mx-auto">
        <div className="mx-auto flex flex-col-reverse md:flex-row">
          <div className="w-full flex flex-col md:pr-12">
            <h1 className="title-font text-center md:text-left sm:text-4xl text-3xl my-4 lg:mt-0 font-medium text-gray-900">
              Plomberie
            </h1>
            <div className="flex mb-4">
              <a
                className={`flex-grow ${
                  activeLink === 'installation'
                    ? 'text-primary-900 border-b-2 border-primary-500'
                    : 'border-b-2 border-gray-300'
                } py-2 text-lg px-1 cursor-pointer`}
                onClick={handleClickInstallation}>
                Installation
              </a>
              <a
                className={`flex-grow ${
                  activeLink === 'urgence'
                    ? 'text-primary-900 border-b-2 border-primary-500'
                    : 'border-b-2 border-gray-300'
                } py-2 text-lg px-1 cursor-pointer`}
                onClick={handleClickEmergency}>
                Urgence
              </a>
            </div>
            <div className="grow">
              {showInstallationContent && (
                <p className="leading-relaxed mb-4">
                  Belgique-chauffage est spécialisé dans les installations de plomberie sur mesure.
                  Nos experts mettent en place des systèmes de plomberie adaptés à vos besoins. Que
                  ce soit pour une nouvelle construction ou une rénovation, nous vous offrons des
                  solutions fiables et durables. Faites confiance à notre équipe qualifiée pour une
                  installation de plomberie de qualité supérieure.
                </p>
              )}
              {showEmergencyContent && (
                <p>
                  Lorsque vous faites face à une urgence en plomberie, Belgique-chauffage est là
                  pour vous aider rapidement et efficacement. Notre équipe d'experts réagit
                  rapidement à votre appel d'urgence, en mettant en œuvre les solutions nécessaires
                  pour résoudre le problème. Que ce soit une fuite d'eau, un égout bouché ou tout
                  autre souci urgent, vous pouvez compter sur notre savoir-faire et notre expérience
                  pour traiter efficacement la situation. Faites confiance à Belgique-chauffage pour
                  un traitement professionnel des urgences en plomberie.
                </p>
              )}
            </div>
            <div className="flex pb-6">
              {showInstallationContent ? (
                <Link
                    to="/contact"
                  className="flex items-center ml-auto text-white bg-secondary-400 border-0 py-2 px-6 focus:outline-none hover:bg-secondary-600 rounded">
                  Contactez-nous
                </Link>
              ) : (
                <a
                  href="tel:0471/71.72.73"
                  className="flex items-center ml-auto text-white bg-secondary-400 border-0 py-2 px-6 focus:outline-none hover:bg-secondary-600 rounded">
                  Téléphonez-nous
                </a>
              )}
              {showInstallationContent && (
                <a
                  href="/plomberie"
                  className="flex ml-4 text-primary-400 border-2 border-primary-400 py-2 transition ease-in duration-200 px-6 focus:outline-none hover:bg-primary-500 hover:text-white rounded">
                  En savoir plus
                </a>
              )}
            </div>
          </div>
          <img
            alt="ecommerce"
            className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded mb-4 md:mb-0"
            src="/img/robinet.jpg"
          />
        </div>
      </div>
    </section>
  );
};

export default PlumberSectionPart;
