import React, { useState } from "react";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    service: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus("Envoi en cours...");

    try {
      const response = await fetch("https://belgiquechauffage.be/mail/sendMail.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.json();
      if (result.status === "success") {
        setStatus("Email envoyé avec succès !");
        setFormData({ name: "", email: "", phone: "", address: "", service: "", message: "" });
      } else {
        setStatus("Erreur lors de l'envoi du mail.");
      }
    } catch (error) {
      setStatus("Erreur de connexion au serveur.");
    }
  };

  return (
      <div className="w-full flex items-center justify-center py-12">
        <form className="bg-white p-12 rounded-2xl shadow-lg w-full max-w-3xl" onSubmit={handleSubmit}>
          <h1 className="text-primary-700 text-[2.7rem] leading-[3rem] font-[Viga] mb-6 text-center">
            Demande de contact
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input name="name" type="text" placeholder="Nom" value={formData.name} onChange={handleChange} className="input-style" required />
            <input name="email" type="email" placeholder="Email" value={formData.email} onChange={handleChange} className="input-style" required />
            <input name="phone" type="tel" placeholder="Téléphone" value={formData.phone} onChange={handleChange} className="input-style" required />
            <input name="address" type="text" placeholder="Adresse" value={formData.address} onChange={handleChange} className="input-style" required />
          </div>

          <div className="mt-6">
            <label className="block text-primary-700 font-semibold mb-2">Service demandé</label>
            <select name="service" value={formData.service} onChange={handleChange} className="input-style w-full" required>
              <option value="">Sélectionner un service</option>
              <option value="installation">Installation</option>
              <option value="réparation">Réparation</option>
              <option value="entretien">Entretien</option>
            </select>
          </div>

          <div className="mt-6">
            <label className="block text-primary-700 font-semibold mb-2">Votre message</label>
            <textarea
                name="message"
                placeholder="Expliquez votre demande..."
                value={formData.message}
                onChange={handleChange}
                className="input-style w-full h-32"
                required
            />
          </div>

          <button type="submit" className="bg-secondary-700 text-white py-3 px-12 rounded-full mt-6 w-full md:w-auto">
            Envoyer
          </button>

          <p className="mt-4 text-center">{status}</p>
        </form>
      </div>
  );
};
