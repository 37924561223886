
import landing01 from '../../assets/img/landing01.png';
import {Link} from "react-router-dom";

const FullPage = () => {
  return (
    <section className="relative flex items-center justify-center flex-1 py-32 overflow-hidden bg-gray-100 rounded-lg shadow-lg lg:mb-12 lg:mb-18 min-h-96 shrink-0 md:pt-48 md:py-20 xl:py-48">
      <img
        src={landing01}
        loading="lazy"
        alt="Image représentant un technicien. Entreprise de chauffage près de Nivelles, Wavre, Namur"
        className="absolute inset-0 object-cover object-center w-full h-full"
      />

      <div className="absolute inset-0 bg-primary-100 opacity-80 mix-blend-multiply"></div>

      <div className="relative flex flex-col items-center p-4 sm:max-w-xl">
        <h1 className="mb-8 text-4xl lg:!leading-[4.5rem] font-bold text-center text-white sm:text-5xl md:mb-12 md:text-6xl">
          Votre référent chauffagiste en Belgique
        </h1>
        <p className="mb-4 text-base text-center text-white sm:text-base md:mb-8">
          Chauffagistes agréés couvrant toute la wallonie et Bruxelles ! Vous propose ses services
          pour l'installation, l'entretien et le dépannage de votre système de chauffage et de votre
          installation sanitaire.
        </p>

        <div className="flex w-full flex-col gap-2.5 sm:flex-row sm:justify-center">
            <Link
                to="/contact"
                className="inline-block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 ease-in rounded-lg outline-none bg-secondary-500 ring-secondary-300 hover:bg-secondary-600 focus-visible:ring active:bg-secondary-700 md:text-base">
                Contactez-nous
            </Link>
        </div>
      </div>
    </section>
  );
};

export default FullPage;
